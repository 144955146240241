import React, {useEffect, useRef} from 'react';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min';
import {useTranslation} from 'react-i18next';

const Select2Wrapperins = ({options, value, onChange}) => {
  const selectRef = useRef(null);

  useEffect(() => {
    // $(selectRef.current)
    //   .select2({
    //     placeholder: '-- Select --',
    //     data: options,
    //   })
    //   .val(value) // Set initial value
    //   .trigger('change'); // Trigger change event manually

    $(selectRef.current).select2();

    // Handle Select2 change event
    $(selectRef.current).on('select2:select', (e) => {
      const selectedValue = e.params.data.id;
      onChange(selectedValue); // Call onChange with the selected value
    });

    // Clean up Select2 instance
    return () => {
      // $(selectRef.current).off('select2:select').select2('destroy');
      $(selectRef.current).select2('destroy');
    };
  }, [options, value, onChange]);

  // Update Select2 value programmatically
  useEffect(() => {
    if (selectRef.current) {
      $(selectRef.current)
          .val(value)
          .trigger('change');
    }
  }, [value]);

  const {t} = useTranslation();

  return (

      <select ref={selectRef} className="select2-container" style={{width: '100%'}}>
        <option value="">-- {t('Select')} --</option>
        {options && options.map((option) =>
            <option key={option.id} id={option.id}
                    value={`${option.description}#${option.id}`}>{t(option.description)}</option>
        )}
      </select>


  );
};

// <option key={option.id} id={option.id} value={`${option.description}#${option.id}`}>{t(option.description)}</option>
export default Select2Wrapperins;