
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import './CustomRadio.css';

const Selectoption = ({ name, options, value, onChange }) => {
  debugger
  const selectOpt = useRef(null);

  useEffect(() => {
    $(selectOpt.current).select2();

    // Handle Select2 change event
    $(selectOpt.current).on('select2:select', (e) => {
      const selectedValue = e.params.data.id;
      debugger
      onChange(name, selectedValue); // Call onChange with the correct name and value
    });

    // Clean up Select2 instance
    return () => {
      $(selectOpt.current).select2('destroy');
    };
  }, [options, name, onChange]);

  // Update Select2 value programmatically
  useEffect(() => {
    if (selectOpt.current) {
      $(selectOpt.current)
        .val(value)
        .trigger('change');
    }
  }, [value]);

  const { t } = useTranslation();

  return (
    <select
    debugger
      ref={selectOpt}
      className="form-control"
      style={{ borderRadius: '5px', height: '40px' }} // Add your desired height and border-radius here
    >
      <option value="">-- {t('Select')} --</option>
      {name !== 'occupation' ? (
        options.map((option) => (
          <option key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </option>
        ))
      ) : (
        
        options.map((option) => (
          
          <option key={option.CODE} value={option.ENGLISH}>
            {option?.ENGLISH} - {option?.ARABIC}
          </option>
        ))
      )}
    </select>
  );
};

export default Selectoption;


