import React, { Fragment , useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';

function Outboundplan() {
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();


    const [dhofarData, setDhofarData] = useState(false);  
    useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarDataoutbound');
      if (storedDhofarString!==undefined) {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // alert("token expired");
        // window.location.href='/';
      }
    }, []);

    

    const [appData, setAppData] = useState(false);
    const [appDataf, setAppDataf] = useState(false);
    useEffect(() => {
      const storedAppString = sessionStorage.getItem('outboundapplicationData');
        if (storedAppString) {
          const storedApp = JSON.parse(storedAppString);
          setAppData(storedApp);
        }
    }, []);

  const [responseData, setResponseData] = useState(null);

  const postData = [{ token: dhofarData }, appData ];


  const [vendorKey, setVendorKey] = useState('');
  
  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
      
      
      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
        })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
            console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  useEffect(() => {

  if(vendorKey!=='' && responseData==null)
  {

  fetch('https://api.superjetom.com/get_outbound_quotation', {
  method: 'POST',
  headers: {
      'Authorization': `Bearer ${vendorKey}`,
      'Content-Type': 'application/json',
  },
  body: JSON.stringify(postData),
  })
  .then(response => response.json())
  .then(data => {
    // debugger
      setResponseData(data.result.data.amount);
      setAppDataf(data.result.data);
  })
  .catch(error => {
      console.error('Error posting data:', error);
  });

}

}, [vendorKey, postData, responseData]);



    const [formData, setFormData] = useState({
      insuranceplan: '',
    });

    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      setFormData({ ...formData, 'insuranceplan': event.target.value });
    };

    
    const handleSubmit = (event) => {
  
      event.preventDefault();
  
      if(selectedOption===null) {
          alert('Please choose plan');
          return false;
        }
      else
      {
      setLoading(true);
  
      event.preventDefault();
  
        var outboundplandata   = {
          'basicplan': formData.insuranceplan,
          "policy_fee": responseData.policy_fee,
          "government_fee": responseData.government_fee,
          "emergency_fund_fee": responseData.emergency_fund_fee,
          "vat": responseData.vat,
          "total": responseData.total
          
        }
  
        sessionStorage.setItem("outboundPlan", JSON.stringify(outboundplandata));

        sessionStorage.setItem("outboundappdataf", JSON.stringify(appDataf));
  
  
        window.location.href = `/outbound-question`;
      }
  
    };


  return (
    <Fragment>

    <div className="header_section1">

    <Navbar />


    <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">            
              <h3 className="abt-txt my-5">{t('Outbound')} {t('Plan')}</h3>
          </div>
      </div>


    </div>
       
 
        <div className="features_section my-1">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

                    <Col md={6} className="myforms my-5"> 



                            <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose Plan' : 'إختر الخطة'}</h4>

                            <form onSubmit={handleSubmit}>

                            {!responseData && ( <div className="mt-2" style={{direction:'ltr'}}><b>Processing...</b></div>) }

                            {responseData && (

                              <div>

                            <div class="align-items-center travel-selection-option">
                                <b>{responseData.base_price} OMR</b>
                                <input type="radio" name="travelplan" value={responseData.base_price} className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                            </div>

                            <button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>

                            </div>

                            )}

                            </form>


                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    {loading && (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    )}

    </Fragment>
  );
}

export default Outboundplan;
