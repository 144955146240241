import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';


function AccidentCover() {

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);

  

  const handleqOptionChange = (event) => {

    // if (event.target.value === 'No') {
    //   alert("The outbound plans are available only for residents of Oman.");
    //   setButtonDisabled(true);
    //   return;
    // }
    // else {
    //   setButtonDisabled(false);
    // }

  };


  const [vendorKey, setVendorKey] = useState('');
  
  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array









  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href='/add-beneficiary';

  

  };
  const [dhofarData, setDhofarData] = useState(false);  
  const [age, setAge] = useState(null);
    useEffect(() => {
      
  
    const storedDhofarString = Cookies.get('dhofarData'); //sessionStorage.getItem('userData')
    
      if (storedDhofarString!==undefined) {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // alert("token expired");
        // window.location.href='/';
      }

    }, []);

    const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()+1).toISOString().split('T')[0];


  const [dobValue, setdobValue] = useState('');
    const handledobChange = (event) => {
      const selectedDob = event.target.value;
        setdobValue(event.target.value);

        const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();
    
    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }
    
    setAge(calculatedAge);
    };

    const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    const handlegenderChange = (event) => {
      setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
      setSelectedgenderOption(event.target.value);
    };
    
    const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
    const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
    const handlemaritalChange = (event) => {
      setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
      setSelectedmaritalOption(event.target.value);
    };
    //getgender

    const [goptions, setGender] = useState([]);

    useEffect(()=>{
      if(goptions && goptions.length===0 && vendorKey!=='')
    {

    fetch('https://api.superjetom.com/getGender', {
    method: 'POST',
    headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
    })
    .then(response => response.json())
    .then(data => {
      
      setGender(data.result.data.response_data);
      
    })
    .catch(error => {
        console.error('Error posting data:', error);
    });

    }
    }
    );

    //getmaritalstatus

    const [moptions, setMarital] = useState([]);
useEffect(()=>{
  if(moptions && moptions.length===0 && vendorKey!=='')
  {

  fetch('https://api.superjetom.com/getMaritalStatus', {
  method: 'POST',
  headers: {
      'Authorization': `Bearer ${vendorKey}`,
      'Content-Type': 'application/json',
  },
  body: JSON.stringify(postData),
  })
  .then(response => response.json())
  .then(data => {
   
    
    setMarital(data.result.data.response_data);
    

  })
  .catch(error => {
      console.error('Error posting data:', error);
  });

  }

})

// const handleSubmit = async (event) => {

//   event.preventDefault();

//   if (selectedOption === null) {
//     alert('Please choose plan');
//     return false;
//   }
// }
   
    
   




  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident Cover   ' : 'تغطية الحوادث الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h3 className="abt-txt ">{'9.900 OMR'}</h3>
              <h2 className="px-0 py-1">{'Sum Assured 3,000'}</h2>
              <h2 className="px-0 py-1">{'Repatriation of Mortal Remains to home country (Up to) 400.000'}</h2>
              {/* <h2 className="px-0 py-1">{'Air ticket expenses for medically unfit to work (Up to) 100.000'}</h2> */}
<h2> </h2>
              <button type="submit" disabled={buttonDisabled} onClick={handleSubmit} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>


              {/* <form onSubmit={handleSubmit}>

            <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('Gender')}</span>
                  </div>
                  <select name="gender" className="form-control" onChange={handlegenderChange} required>
                    <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    {goptions && goptions.map((opt) =>
                      <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
                    )}
                  </select>
                </div>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Marital Status' : 'الحالة الأجتماعية'}</span>
                  </div>
                  <select name="maritalstatus" className="form-control" onChange={handlemaritalChange}
                    required>
                    <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    {moptions && moptions.map((mopt) =>
                      <option key={mopt.id} id={mopt.id}
                        value={mopt.description}>{t(mopt.description)}</option>
                    )}
                  </select>
                </div>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('DOB')}</span>
                  </div>
                  <input type="date" name="dob" max={maxDate} className="form-control" onChange={handledobChange} required />
                </div>
                {age !== null && (
                  <div className="mt-2">
                    <p>You are {age} years old.</p>
                  </div>
                )}
                <button type="submit" disabled={buttonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>


              </form> */}


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default AccidentCover;
