import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';

function Outboundquestion() {

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [dhofarData, setDhofarData] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarDataoutbound');
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }
  }, []);

  const [appDataf, setAppDataf] = useState(false);
  useEffect(() => {
    const storedAppStringf = sessionStorage.getItem('outboundappdataf');
    if (storedAppStringf) {
      const storedAppf = JSON.parse(storedAppStringf);
      setAppDataf(storedAppf);
    }
  }, []);

  const [appData, setAppData] = useState(false);
  useEffect(() => {
    const storedAppString = sessionStorage.getItem('outboundapplicationData');
    if (storedAppString) {
      const storedApp = JSON.parse(storedAppString);
      setAppData(storedApp);
    }
  }, []);

  const postData = { token: dhofarData };


  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  const [ques, setQues] = useState(null);

  if (vendorKey !== '' && ques === null) {

    fetch('https://api.superjetom.com/get_outbound_questions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger
        setQues(data.result.data);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  }


  const [selectedqOption1, setSelectedqOption1] = useState(null);
  const [selectedqOptionid1, setSelectedqOptionid1] = useState(false);

  const [selectedqOption2, setSelectedqOption2] = useState(null);
  const [selectedqOptionid2, setSelectedqOptionid2] = useState(false);

  const handleqOptionChange = (event, extraValue) => {

    if (extraValue === 0) {
      setSelectedqOption1(event.target.value);
      setSelectedqOptionid1(event.target.id);
    }
    else if (extraValue === 1) {
      setSelectedqOption2(event.target.value);
      setSelectedqOptionid2(event.target.id);
    }

  };

  const [loading, setLoading] = useState(false);

  const signatureRef = useRef();

  const handleSubmit = async () => {

    if (selectedqOption1 !== selectedqOptionid1) {
      if (selectedqOption1 == null) {
        alert(`Select answer to continue`);
      }
      else {
        alert(`click First Question ${selectedqOptionid1} to continue`);
      }

      return;
    }
    else if (selectedqOption2 !== selectedqOptionid2) {
      if (selectedqOption2 == null) {
        alert(`Select answer to continue`);
      }
      else {
        alert(`click Second Question ${selectedqOptionid2} to continue`);
      }
      return;
    }
    else {

      setLoading(true);

      const signature = signatureRef.current.toDataURL();

      const noActualCircumstances = "No";

      const inGoodHealth = "Yes";

      const combinedData = { ...appData, ...appDataf, noActualCircumstances, inGoodHealth, signature };

      // debugger

      const pdata = [{ token: dhofarData }, combinedData];
      debugger
      await fetch('https://api.superjetom.com/add_outbound_policy', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pdata),
      })
        .then(response => response.json())
        .then(data => {
          sessionStorage.setItem("outboundpolicyNumber", JSON.stringify(data.result.policy_number));
          window.location.href = `/outbound-summary`;
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }

  };


  //     const [isChecked, setIsChecked] = useState(false);
  //     const [isChecked1, setIsChecked1] = useState(false);
  //     const [isChecked2, setIsChecked2] = useState(false);

  //     const handleCheckboxChange = (event) => {
  //         setIsChecked(event.target.checked);
  //     };
  //     const handleCheckboxChange1 = (event) => {
  //       debugger
  //       setIsChecked1(event.target.checked);
  //   };
  //   const handleCheckboxChange2 = (event) => {
  //     setIsChecked2(event.target.checked);
  // };

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  // Function to check if all checkboxes are checked
  const allChecked = isChecked1 && isChecked2 && isChecked3;

  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };

  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('Outbound')} {t('Plan')}</h3>
          </div>
        </div>


      </div>


      <div className="features_section my-1">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">Question</h4>

              {/* <form onSubmit={handleSubmit}> */}

              {ques && ques.map((mopt, index) =>

                <div style={{ direction: 'ltr' }}>

                  <p>{mopt.English}</p>

                  <Row>
                    <Col md={4}>
                      <div class="align-items-center travel-selection-option">
                        {(lnstr === 'en') ? 'YES' : 'نعم\n'}
                        <input type="radio" name={`questionans${index}`} id={mopt.accepted_answer} value="Yes" onChange={(e) => handleqOptionChange(e, index)} className="d-inline-block option-circle ml-auto" />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div class="align-items-center travel-selection-option">
                        {(lnstr === 'en') ? 'NO' : 'لا'}
                        <input type="radio" name={`questionans${index}`} id={mopt.accepted_answer} value="No" onChange={(e) => handleqOptionChange(e, index)} className="d-inline-block option-circle ml-auto" />
                      </div>
                    </Col>
                  </Row><br />

                </div>

              )}

              <Row>

                <Col md={12}>

                  <b>Please put your signature here</b><br />

                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                    style={{ border: '1px solid #ccc' }}
                    onEnd={handleSignature} // Detect when drawing ends
                  />
                  <div>
                    <button type="button"
                      style={{ backgroundColor: !isSignatureEmpty ? '#61dafb' : 'grey', borderColor: '#61dafb' }}
                      className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                        clearSignature();
                      }}
                      disabled={isSignatureEmpty}
                    >
                      Clear Signature
                    </button>
                  </div>

                </Col>

              </Row>


              <Row>

                <Col md={12} style={{ direction: 'ltr' }}>

                  <p><input type="checkbox" checked={isChecked1}
                    onChange={() => setIsChecked1(prev => !prev)} name="note" /> I/We agree that this application shall form the basis of a contract between me/us and Dhofar Insurance Co. (SAOG). <br /><br />

                    <input type="checkbox" checked={isChecked2}
                      onChange={() => setIsChecked2(prev => !prev)} name="note" />  I/We agree to abide by the terms and conditions set out in the Policy. <br /><br />
                    <input type="checkbox" checked={isChecked3}
                      onChange={() => setIsChecked3(prev => !prev)} name="note" /> I/We hereby declare that information entered in this application is valid/correct and to the best of my/our knowledge. <br /><br />
                    {/*                               
                              I/We agree to abide by the terms and conditions set out in the Policy. <br /><br />
                              I/We hereby declare that information entered in this application is valid/correct and to the best of my/our knowledge. */}
                  </p>

                </Col>

              </Row>


              <button type="submit" disabled={!allChecked} onClick={handleSubmit} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>

              {/* </form> */}


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default Outboundquestion;