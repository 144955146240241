
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import './CustomRadio.css';

const Selectoptionocc = ({ name, options, value, onChange }) => {
    const selectOpt1 = useRef(null);

    useEffect(() => {
      // Initialize Select2
      $(selectOpt1.current).select2();
  
      // Handle Select2 change event
      $(selectOpt1.current).on('select2:select', (e) => {
        // Find the selected option by matching the code (value)
        const selectedOption = options.find(option => option.CODE === e.params.data.id);
  
        // Call onChange with both the English name and the code
        if (selectedOption) {
            debugger
          onChange(name, selectedOption.ENGLISH, selectedOption.CODE);  // Pass both ENGLISH and CODE
        }
      });
  
      // Clean up Select2 instance on unmount
      return () => {
        $(selectOpt1.current).select2('destroy');
      };
    }, [options, name, onChange]);
  
    // Update Select2 value programmatically if value changes
    useEffect(() => {
      if (selectOpt1.current) {
        $(selectOpt1.current)
          .val(value)
          .trigger('change');
      }
    }, [value]);
  
    const { t } = useTranslation();
  
    return (
      <select
        ref={selectOpt1}
        className="form-control"
        style={{ borderRadius: '5px', height: '40px' }} // Add your desired height and border-radius here
      >
        <option value="">{`-- ${t('Select')} --`}</option>
        {options.map((option) => (
          <option key={option.CODE} value={option.CODE}>
            {option.ENGLISH} - {option.ARABIC}
          </option>
        ))}
      </select>
    );
};

export default Selectoptionocc;


