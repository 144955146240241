import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');

function DownloadSanadPolicy() {

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();
  const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
  const api_headers = {
    'Authorization': 'Bearer ' + vendor_key,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }

  const [dhofarData, setDhofarData] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [policyDetails, setPolicyDetails] = useState(null);
  const [policyCoverDetails, setPolicyCoverDetails] = useState(null);
  const [sandaUrlData, setSandaUrlData] = useState(null);

  useEffect(async () => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('policyno');

    setPolicyNo(policy_no);



    var policy_api_data = {
      "policy_no": policy_no
    };
    const response = await fetch(`${base_url}/get_sanad_policy_details_by_policy_no`, {
      method: 'POST',
      headers: api_headers,
      body: JSON.stringify(policy_api_data),
    });

    const data = await response.json();

    if (data.message === 'success') {

      setPolicyDetails(data?.result[0]);

      var policy_cover_details = data?.result[0]?.policy_data;
      if (policy_cover_details) {
        policy_cover_details = JSON.parse(policy_cover_details);
        setPolicyCoverDetails(policy_cover_details);
      } else {
        setPolicyCoverDetails(null);
      }

      // Handle successful response
      // Example: setPaymentUrl(data.result.data.payment_url);
    } else {
      setPolicyDetails(null);
      setPolicyCoverDetails(null);
      // Handle failure
      // setPolicyLink('');
    }
var url_data = Cookies.get('sanadUrlData');
debugger
if(url_data){
  setSandaUrlData(JSON.parse(url_data));
}
   
    
  }, []);

  useEffect(async () => {

    const fetchData = async () => {
      try {
        var accessTokenData = await createAccessToken();
        var accessToken = accessTokenData?.result?.data?.token;
        if (accessToken != null && accessToken != undefined) {
          debugger
          setDhofarData(accessToken);

        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const createAccessToken = async () => {
    try {
      const response = await fetch(base_url + '/create_sanad_personal_accident_token', {
        method: 'GET',
        headers: api_headers
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      if (data.message === 'success') {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const [benData, benAppData] = useState(false);





  const redirect_url = "https://superjetom.com/";

  // const [paymentUrl, setPaymenturl] = useState('');





  //   useEffect(async () => {

  //         const postvData = { policy_no: policyNo };
  //   debugger
  //     const fetchPolicyData = async () => {

  //       debugger
  //       // fetch(base_url+ '/get_sanad_policy_details_by_policy_no', {
  //       //   method: 'POST',
  //       //   headers: api_headers,

  //       //   body: JSON.stringify(postvData),
  //       //   })
  //       //   .then(response => response.json())
  //       //   .then(data => {
  //       //     debugger
  //       //     if(data.message == 'success'){
  //       //       debugger

  //       //     }else{
  //       //       // setPolicyLink('')
  //       //     }
  //       //     // debugger
  //       //       // setPaymenturl(data.result.data.payment_url);
  //       //   })
  //       //   .catch(error => {
  //       //       console.error('Error posting data:', error);
  //       //   });
  //     //   const response = await fetch(`${base_url}/get_sanad_policy_details_by_policy_no`, {
  //     //     method: 'POST',
  //     //     headers: api_headers,
  //     //     body: JSON.stringify(postData),
  //     // });

  //     // const data = await response.json();

  //     // if (data.message === 'success') {
  //     //     // Handle successful response
  //     //     // Example: setPaymentUrl(data.result.data.payment_url);
  //     // } else {
  //     //     // Handle failure
  //     //     // setPolicyLink('');
  //     // }
  //     };

  //     fetchPolicyData();
  // }, []);

  // if(vendor_key!=='' && policyNo != null&&dhofarData!==''&&dhofarData!==undefined&&dhofarData!==null)
  // {

  //   fetch(base_url+ '/get_sanad_policy_details_by_policy_no', {
  //   method: 'POST',
  //   headers: api_headers,

  //   body: JSON.stringify(postvData),
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //     debugger
  //     if(data.message == 'success'){
  //       debugger

  //     }else{
  //       // setPolicyLink('')
  //     }
  //     // debugger
  //       // setPaymenturl(data.result.data.payment_url);
  //   })
  //   .catch(error => {
  //       console.error('Error posting data:', error);
  //   });

  // }






  // const postData = [{ token: dhofarData ,policy_number: policyNo }];
  const postData = { token: dhofarData, policy_no: policyNo };

  if (vendor_key !== '' && policyNo != null && dhofarData !== '' && dhofarData !== undefined && dhofarData !== null) {

    fetch(base_url + '/download_sanad_personal_accident_policy_receipt', {
      method: 'POST',
      headers: api_headers,

      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        debugger
        if (data.message == 'success') {
          debugger
          setPolicyLink(data.result.data.payment_receipt_url);
        } else {
          setPolicyLink('')
        }
        // debugger
        // setPaymenturl(data.result.data.payment_url);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  }


  return (
    <Fragment>
      <div className="features_section layout_padding my-5">
        {/* <div className="container-fluid" style={{ padding: '10px 20px', display: 'flex' }}>
          <button style={{ width: '100px' }}>Back</button>
        </div> */}
        {/* {
          // policyLink != null && policyLink != '' ?
            true?
            <div className="container-fluid bg-breadcrumb">
              <div className="container text-center">
                <h3 className="abt-txt">Downlod Policy {policyNo}</h3>
              </div>
            </div> :
            <div className="text-center">
              <h3 className="abt-txt">Policy Status {policyNo}</h3>
            </div>
        } */}

        <div>

          <div className="row">

            <Col md={3}></Col>


            <Col md={6} className="myforms my-3 ">
              <div style={{ display: 'flex', paddingBottom: '20px' }}>
                <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                  var isValidUrl = regex.test(sandaUrlData?.back_url);

                  if(isValidUrl){
                    window.open(sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no , '_blank')
                  }else{
                    window.location.reload();
                  }
                  
                }} >Back</button>
              </div>

              <div style={{ textAlign: 'left', fontSize: '20px' }}>
                <b>Policy Details  - {policyDetails != null ? policyDetails?.policy_no : ''}</b>
              </div>

              <br />

              <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                <tbody>

                <tr><td style={{width:'50%'}}><b>Name</b>
                  </td><td>{policyDetails != null ? policyDetails?.first_name : ''}</td></tr>

                  <tr><td><b>Occupation</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.personalInformation?.occupation?.toString() : ''}</td></tr>


                  <tr><td><b>Passport</b>
                  </td><td>{policyDetails != null ? policyDetails?.sanad_passport_no : ''}</td></tr>

                  <tr><td><b>Sanad Reference No</b>
                  </td><td>{policyDetails != null ? policyDetails?.sanad_reference_no : ''}</td></tr>

                  <tr><td><b>Civil ID</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.civil_id : ''}</td></tr>

                  <tr><td><b>Total Fee (OMR)</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.amount?.total?.toString() : ''}</td></tr>

                  <tr><td><b>Created</b>
                  </td><td>{policyDetails != null ? policyDetails?.created_at.toString().split('T')[0] : ''}</td></tr>

                </tbody>

              </table>

              {/* <a href='outbound-travel-details'><button type="button" class="btn btn-primary-outline">Edit</button></a> &nbsp; <a href={(paymentUrl) ? paymentUrl : ''}><button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-2 px-5">{(lnstr === 'en') ? 'Proceed to payment' : 'الدفع'}</button></a> */}


              <div className="text-center">
                {
                   policyLink != null && policyLink != '' ?
                  // true ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <button
                        onClick={() => {
                          debugger
                          if (policyLink) {
                            var url = policyLink;
                            const link = document.createElement('a');
                            link.href = url;
                            link.target = '_blank';
                            link.download = url.split('/').pop(); // Extracts filename from URL
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } else {
                            alert('Policy error')
                          }

                        }}
                        style={{ fontWeight: 'bold' }}
                      >
                        Download Policy Document
                      </button>
                      <div style={{ margin: '10px 0' }} >
                        <button style={{ marginLeft: 'auto', fontWeight: 'bold' }}
                        onClick={() => {
                          debugger
                          const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                          var isValidUrl = regex.test(sandaUrlData?.return_url);
        
                          if(isValidUrl){
                            window.open(sandaUrlData?.return_url, '_blank')
                          }else{
                            window.location.reload();
                          }
                          
                        }}
                        >Go To Sanad</button>
                      </div></div>
                    : policyLink === '' ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h2>Policy Payment Pending</h2>
                      <div>
                        <button
                          onClick={() => {

                            if (policyDetails?.payment_link) {
                              window.open(policyDetails?.payment_link, '_blank')

                              // Reload the current page (form tab)
                              window.location.reload();
                            }

                          }} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold' }}>Make Payment</button></div></div> : "Loading"
                }
              </div>


            </Col>

            <Col md={3}></Col>

          </div>



        </div>


      </div>
    </Fragment>
  );
}

export default DownloadSanadPolicy;