import React, { Fragment, useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from './loaderred.gif';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Profile = ({ usrtok }) => {

    const { t } = useTranslation();

    

    const [userData, setUserData] = useState(false);
  
    useEffect(() => {
  
      // debugger;
  
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
      if (storedUserString!=='undefined') {
        const storedUser = JSON.parse(storedUserString);
        setUserData(storedUser);
      }
    }, []);
  
    usrtok = userData.access_token;
  
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);


    
  const [vendorKey, setVendorKey] = useState('');
  
  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
      
      
      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
        })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
            console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array

  
  
  useEffect(() => {
        
    var vkData = { vendor_key: vendorKey };

      if(!Cookies.get('userData'))
      {
        alert('Login Session Expired');
        window.location.href = '/';
      }
      else if(vendorKey!='')
        {

      

    fetch('https://api.superjetom.com/each_traveller_details', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${usrtok}`,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(vkData),
      })
      .then(response => response.json())
      .then(data => {
        // debugger
        setProfile(data.result[0]);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
    }
  }, [usrtok, vendorKey]);


  
    // useEffect(() => {
    //   const fetchProfile = async () => {
    //     try {
    //       // Fetch profile data using token
    //       const response = await fetch('https://api.superjetom.com/each_traveller_details', {
    //         method: 'GET',
    //         headers: {
    //           'Authorization': `Bearer ${usrtok}` // <-- Using 'usrtok' here
    //           //'Content-Type': 'application/json' // Adjust content type as needed
    //         }
    //       });
  
  
    //       if (response.ok) {
    //       const profileData = await response.json();
    //       setProfile(profileData);
    //       }
  
    //     } catch (error) {
    //       setError(error.message);
    //     }
    //   };
  
    //   fetchProfile();
  
    //   // Clean up function to prevent memory leaks
    //   return () => {
    //     setProfile(null);
    //     setError(null);
    //   };
    // }, [usrtok]); // <-- Add 'usrtok' to the dependency array
  
    // if (error) {
    //   return <div>Error: {error}</div>;
    // }
  
    if (!profile) {
      return <div className="overlay">
      <div className="spinner"></div>
    </div>;
    }
  
  
  


  return (
    <div>

      <div className="header_section1">

      <Navbar />


      <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">            
              <h3 className="abt-txt my-5">{t('PROFILE')}</h3>
          </div>
      </div>


      </div>
        

      <div className="features_section layout_padding my-1">
      <div className="container">
                
                <div className="row g-4 justify-content-center">
                    
                    <div className="col-12 wow fadeInUp" data-wow-delay="0.2s">
                      
                    
                    <h3>{t('Profile')} {t('Details')}</h3>

                    {profile && 
                    
                    <table className='mt-3'>
                      <tr><td>{t('First')} {t('Name')}</td><td>&nbsp; : &nbsp;</td><td>{profile.first_name}</td></tr>
                      <tr><td>{t('Last')} {t('Name')}</td><td>&nbsp; : &nbsp;</td><td>{profile.last_name}</td></tr>
                      <tr><td>{t('Email')}</td><td>&nbsp; : &nbsp;</td><td>{profile.email}</td></tr>
                    </table>

                    }


                    </div>
                </div>
            </div>
        </div>


        <Footer />

    </div>
  );
};

export default Profile;