import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('./api/api');

function DownloadInboundPolicy() {

  const base_url = config.baseURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();

    const [dhofarData, setDhofarData] = useState(null);  
    const [policyNo, setPolicyNo] = useState(null);  
    const [policyLink, setPolicyLink] = useState(null);  

    useEffect(() => {
      debugger
      const urlSearchString = window.location.search;
      const uparams = new URLSearchParams(urlSearchString);
      const policy_no = uparams.get('policyno');
     
  setPolicyNo(policy_no);
      // var decodeed_mail = decodeURIComponent(escape(window.atob(email)));
      // var decodeed_id = decodeURIComponent(escape(window.atob(id)));
      // decodeURIComponent(escape(window.atob(base64)));
      //  const decodedAgencyId = Buffer.from(id, 'base64').toString('utf-8');
      //         const decodedEmail = Buffer.from(email, 'base64').toString('utf-8');
      // setFormData(prevState => ({
      //   ...prevState,
      //   email: decodeed_mail || '',
      //   id: decodeed_id || ''
      // }));
    }, []);
    // useEffect(() => {
    // const storedDhofarString = Cookies.get('dhofarData');
    //   if (storedDhofarString!=='undefined'&& storedDhofarString!==undefined) {
    //     const storedDhofar = JSON.parse(storedDhofarString);
    //     setDhofarData(storedDhofar);
    //   }
    //   else
    //   {
    //     // debugger
    //     // alert("token expired");
    //     // window.location.href='/';
    //   }
    // }, []);

    // const [appData, setAppData] = useState(false);
    // useEffect(() => {
    //   const storedAppString = sessionStorage.getItem('induranceapplicationData');
    //     if (storedAppString!=='undefined') {
    //       const storedApp = JSON.parse(storedAppString);
    //       setAppData(storedApp);
    //     }
    //     else
    //     {
    //       debugger
    //       // alert("token expired");
    //       // window.location.href='/';
    //     }
    // }, []);


    // const [policyData, setPolicyData] = useState(false);
    // useEffect(() => {
    //   const storedPolicyString = sessionStorage.getItem('policyNumber');
    //     if (storedPolicyString!=="undefined") {
    //       const storedPolicy = JSON.parse(storedPolicyString);
    //       setPolicyData(storedPolicy);
    //     }
    //     else
    //     {
    //       debugger
    //       // alert("token expired");
    //       // window.location.href='/';
    //     }
    // }, []);


    // const [plData, setPlData] = useState(false);
    // useEffect(() => {
    //   const storedPlString = sessionStorage.getItem('insurancePlan');
    //     if (storedPlString!==undefined) {
    //       const storedPl = JSON.parse(storedPlString);
    //       setPlData(storedPl);
    //     }
    // }, []);



    const [vendorKey, setVendorKey] = useState('');
  
    useEffect(() => {

      const postvData = { organization_name: "Superjet" };

      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
        
        
        fetch('https://api.superjetom.com/create_organization', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
          })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
              console.error('Error posting data:', error);
          });


      }

      if (vendorKey !== '' && vendorKey !== null && vendorKey !== undefined) {
         fetch('https://api.superjetom.com/create_inbound_token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
          }
        })
          .then(response => response.json())
          .then(data => {
  
            debugger
  
            const dhdata = data.result.data.token;
  
            const expiryDate = new Date();
            // expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));
  
            // Cookies.set('dhofarData', JSON.stringify(dhdata), { expires: expiryDate });
            setDhofarData(dhdata);
  
           
  
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });

      }

    }, [vendorKey]); // Dependency array



    const redirect_url = "https://superjetom.com/";

    // const [paymentUrl, setPaymenturl] = useState('');

    const postData = [{ token: dhofarData }, {policy_number: policyNo}];

    if(vendorKey!=='' && policyNo !== null&&dhofarData !==null&&dhofarData!=='')
    {

      fetch(base_url+ '/get_inbound_policy_documents', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      })
      .then(response => response.json())
      .then(data => {
        if(data.message == 'success'){
          setPolicyLink(data.result.data.policy_schedule_url);
        }else{
          setPolicyLink('')
        }
        debugger
          // setPaymenturl(data.result.data.payment_url);
      })
      .catch(error => {
          console.error('Error posting data:', error);
      });

    }

  return (
    <Fragment>
      <Navbar />
      <div className="features_section layout_padding my-5">
   {  
   policyLink != null && policyLink != '' ?   
  // true?
   <div className="container-fluid bg-breadcrumb">
      <div className="container text-center">
     <h3 className="abt-txt">Downlod Policy {policyNo}</h3>
      </div>
    </div>:
      <div className="text-center">
     <h3 className="abt-txt">Policy Status {policyNo}</h3>
      </div>
  }
    <div className="text-center">
      {
        policyLink != null && policyLink != ''?  
        // true ?  
        <button
          className="btn btn-primary"
          onClick={() => {
            var url = policyLink;
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = url.split('/').pop(); // Extracts filename from URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          Download Policy Document
        </button> : policyLink === '' ? <h2>Policy Payment Pending</h2> : "Loading"
      }
    </div>
  </div>
        {/* <div className="features_section layout_padding my-5">
        <div className="container">
          <div className="col-md-5">
          {policyLink != null && policyLink != ''?  <button
              className="btn btn-primary" 
              onClick={()=>{
                var url  = policyLink;
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = url.split('/').pop(); // Extracts filename from URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Download Policy Document </button> : policyLink == ''?   <h2>Your Policy Payment Pending</h2>: "Loading"}
          </div>
        </div> 
        </div>*/}
     
      <Footer />

    </Fragment>
  );
}

export default DownloadInboundPolicy;