import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import HomePage from './Home';
import About from './About';
import Partner from './Partner';
import Visa from './Visa';
import Insurance from './Insurance';
import LoginPage from './login/Login';
import SignupPage from './signup/Signup';
import Visatypes from './Visatypes';
import Application from './application/Application';
import Applicationnew from './application/Applicationnew';
import Applicationiframe from './application/Applicationiframe';
import Verify from './application/Verify';
import Orderpage from './Orderpage';
import Orderpagenew from './Orderpagenew';
import Orderfailed from './Orderfailed';
import Profile from './Profile';
import Myorders from './Myorders';
import Travelinsurance from './Travelinsurance';
import Inboundtraveldetails from './Inboundtraveldetails';
import Inboundtravelinfo from './Inboundtravelinfo';
import Inboundtravellerinfo from './Inboundtravellerinfo';
import Insuranceplan from './Insuranceplan';
import Insurancequestion from './Insurancequestion';
import Insurancesummary from './Insurancesummary';
import Outboundtraveldetails from './Outboundtraveldetails';
import Outboundtravelinfo from './Outboundtravelinfo';
import Outboundtravellerinfo from './Outboundtravellerinfo';
import Outboundplan from './Outboundplan';
import Outboundquestion from './Outboundquestion';
import Outboundsummary from './Outboundsummary';
import Founders from './Founders';
import Services from './Services';
import Terms from './Terms';
import Privacy from './Privacy';
import Apipage from './Apipage';
import Travelagent from './Travelagent';
import Demo from './Demo';
import Logout from './Logout';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Confirmpassword from './confirmpassword/Confirmpassword';
import TravellerList from './TravellerList';
import VerificationPage from './Verification/VerificationPage';
import AgentCreated from './Verification/AgentCreated';
import DhofarIframeComponent from './DhofarIframe';
import PersonalAccidentinsurance from './personalaccidentinsurance/personalaccidentinsurance';
import BasicInformation from './personalaccidentinsurance/basicinformation';
import AccidentCover from './personalaccidentinsurance/accidentcover';
import AddBeneficiary from './personalaccidentinsurance/AddBeneficiary';
import PersonalAccident from './sanad/personalaccident';
import DownloadInboundPolicy from './DownloadInboundPolicy';
import DownloadOutboundPolicy from './DownloadOutboundPolicy';
import DownloadSanadPolicy from './sanad/DownloadSanadPolicy';
import Makepayment from './application/Makepayment';
import SanadPolicyStatus from './sanad/SanadPolicyStatus'

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/visa",
    element: <Visa />,
  },
  {
    path: "/demo",
    element: <Demo />,
  },
  {
    path: "/order-failed",
    element: <Orderfailed />,
  },
  {
    path: "/partner",
    element: <Partner />,
  },
  {
    path: "/insurance",
    element: <Insurance />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/visatypes",
    element: <Visatypes />,
  },
  {
    path: "/application",
    element: <Application />,
  },
  {
    path: "/application-new",
    element: <Applicationnew />,
  },
  {
    path: "/application-verify",
    element: <Applicationiframe />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/order-page",
    element: <Orderpage />,
  },
  {
    path: "/order-pagenew",
    element: <Orderpagenew />,
  },
  {
    path: "/travel-insurance",
    element: <Travelinsurance />,
  },
  {
    path: "/inbound-travel-details",
    element: <Inboundtraveldetails />,
  },
  {
    path: "/inbound-travel-info",
    element: <Inboundtravelinfo />,
  },
  {
    path: "/inbound-traveller-info",
    element: <Inboundtravellerinfo />,
  },
  {
    path: "/insurance-plan",
    element: <Insuranceplan />,
  },
  {
    path: "/insurance-question",
    element: <Insurancequestion />,
  },
  {
    path: "/insurance-summary",
    element: <Insurancesummary />,
  },
  {
    path: "/outbound-travel-details",
    element: <Outboundtraveldetails />,
  },
  {
    path: "/outbound-traveller-info",
    element: <Outboundtravellerinfo />,
  },
  {
    path: "/outbound-plan",
    element: <Outboundplan />,
  },
  {
    path: "/outbound-question",
    element: <Outboundquestion />,
  },
  {
    path: "/outbound-summary",
    element: <Outboundsummary />,
  },
  {
    path: "/outbound-travel-info",
    element: <Outboundtravelinfo />,
  },
  {
    path: "/about-us",
    element: <About />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/myorders",
    element: <Myorders />,
  },
  {
    path: "/founders",
    element: <Founders />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/api-page",
    element: <Apipage />,
  },
  {
    path: "/travel-agent",
    element: <Travelagent />,
  },
  {
    path: "/set-password",
    element: <Confirmpassword />
  },
  {
    path: "/travellerlist",
    element: <TravellerList />,
  },
  {
    path: "/verification",
    element: <VerificationPage />,
  },
  {
    path: "/agent-verify",
    element: <AgentCreated />,
  },
  {
    path: "/travel_insurance",
    element: <DhofarIframeComponent />,
  },
  {
    path: "/personal-accident-insurance",
    element: <PersonalAccidentinsurance />,
  },
  {
    path: "/basic-information",
    element: <BasicInformation />,
  },
  {
    path: "/accident-cover",
    element: <AccidentCover />,
  },
  {
    path: "/add-beneficiary",
    element: <AddBeneficiary />,
  },
  {
    path: "/sanad-personal-accident",
    element: <PersonalAccident />,
  },
  {
    path: "/download-policy",
    element: <DownloadInboundPolicy />
  },
  {
    path: "/download-outbound-policy",
    element: <DownloadOutboundPolicy />
  },
  {
    path: "/download-sanad-policy",
    element: <DownloadSanadPolicy />
  },
  {
    path: "/make-payment",
    element: <Makepayment/>
  },
  {
    path:"/sanad-policy-status",
    element:<SanadPolicyStatus/>
  }
]);

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
  document.getElementById('root')
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();