import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';

function Insuranceplan() {

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();


  const [dhofarData, setDhofarData] = useState(false);
  useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarData');
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href = '/';
    }
  }, []);

  const [appData, setAppData] = useState(false);
  const [appDataf, setAppDataf] = useState(false);
  useEffect(() => {
    const storedAppString = sessionStorage.getItem('induranceapplicationData');
    if (storedAppString !== undefined) {
      const storedApp = JSON.parse(storedAppString);
      setAppData(storedApp);
    }
  }, []);

  const [responseData, setResponseData] = useState(null);

  const postData = [{ token: dhofarData }, appData];


  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (vendorKey !== '' && responseData == null) {
      debugger
      fetch('https://api.superjetom.com/getQuotation', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then(response => response.json())
        .then(data => {
          setResponseData(data.result.data.amount);

          setAppDataf(data.result.data);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }

  }, [vendorKey, postData, responseData]);



  const [formData, setFormData] = useState({
    insuranceplan: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({ ...formData, 'insuranceplan': event.target.value });
  };


  const handleSubmit = (event) => {

    event.preventDefault();

    if (selectedOption === null) {
      alert('Please choose plan');
      return false;
    }
    else {
      setLoading(true);

      event.preventDefault();

      var insuranceplandata = {
        'basicplan': formData.insuranceplan,
        "policy_fee": responseData.policy_fee,
        "government_fee": responseData.government_fee,
        "emergency_fund_fee": responseData.emergency_fund_fee,
        "vat": responseData.vat,
        "total": responseData.total

      }

      sessionStorage.setItem("insurancePlan", JSON.stringify(insuranceplandata));

      sessionStorage.setItem("appdataf", JSON.stringify(appDataf));


      window.location.href = `/insurance-question`;
    }

  };





  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Inbound Plan Details' : 'تفاصيل خطة السفر الداخلي'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose Plan' : 'إختر الخطة'}</h4>

              <form onSubmit={handleSubmit}>

                {!responseData && (<div className="mt-2" style={{ direction: 'ltr' }}><b>Processing...</b></div>)}

                {responseData && (

                  <div>

                    <div class="align-items-center travel-selection-option">
                      <b>{responseData.base_price} OMR</b>
                      <input type="radio" name="travelplan" value={responseData.base_price} className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                    </div>

                    <button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>

                  </div>

                )}

              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default Insuranceplan;
