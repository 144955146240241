import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';

function Insurancequestion() {

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [dhofarData, setDhofarData] = useState(false);

  useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarData');
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href = '/';
    }
  }, []);



  const [appDataf, setAppDataf] = useState(false);
  useEffect(() => {
    const storedAppStringf = sessionStorage.getItem('appdataf');
    if (storedAppStringf !== undefined) {
      const storedAppf = JSON.parse(storedAppStringf);
      setAppDataf(storedAppf);
      // narray.push(storedAppf);
      // setMyArray(prevArray => [...prevArray, storedAppf]);
    }
  }, []);

  const [appData, setAppData] = useState(false);
  useEffect(() => {
    const storedAppString = sessionStorage.getItem('induranceapplicationData');
    if (storedAppString !== undefined) {
      const storedApp = JSON.parse(storedAppString);
      setAppData(storedApp);
      // narray.push(storedApp);
      // setMyArray(prevArray => [...prevArray, storedApp]);
    }
  }, []);

  // const myArray = appData, appDataf;

  const [loading, setLoading] = useState(false);

  const postData = { token: dhofarData };


  const [vendorKey, setVendorKey] = useState('');

  const [userData, setUserData] = useState();

  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString != undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    } else {
      // alert("token expired");
      // window.location.href = '/';
    }
  }, []);
  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array



  const [ques, setQues] = useState(null);

  if (vendorKey !== '' && ques === null) {

    fetch('https://api.superjetom.com/getQuestions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger
        setQues(data.result.data[0].English);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  }


  const [selectedqOption, setSelectedqOption] = useState(null);

  const handleqOptionChange = (event) => {
    setSelectedqOption(event.target.value);
  };


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const signatureRef = useRef();
  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };


  // const [poli, setPolicy] = useState(null);

  const handleSubmit = async () => {

    if (selectedqOption !== 'Yes') {
      alert("click yes to continue");
      return;
    }
    else {

      setLoading(true);

      // setAppData(prevArray1 => prevArray1.concat(appDataf));

      const signature = signatureRef.current.toDataURL();

      const inGoodHealth = "Yes";

      const combinedData = { ...appData, ...appDataf, inGoodHealth, signature };

      var pdata = null;

      // const poli = [];
      var policy_url = '';
      if (userData != null && userData != undefined && userData.agency_name != undefined) {
        policy_url = 'https://api.superjetom.com/add_policy_agency_user';
        pdata = [{ token: dhofarData }, { agent_token: userData.access_token }, combinedData];
      } else {
        policy_url = 'https://api.superjetom.com/add_policy';
        pdata = [{ token: dhofarData }, combinedData];
      }

      debugger
      console.log(userData)
      await fetch(policy_url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pdata),
      })
        .then(response => response.json())
        .then(data => {
          debugger
          // if (userData != null && userData != undefined && userData.agency_name != undefined) {
          //   sessionStorage.setItem("policyNumber", JSON.stringify(data.result.data.policy_number));
          // } else {
          //   sessionStorage.setItem("policyNumber", JSON.stringify(data.result.policy_number));
          // }
          sessionStorage.setItem("policyNumber", JSON.stringify(data.result.policy_number));
          window.location.href = `/insurance-summary`;
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

      // debugger

      // window.location.href = `/Insurancesummary`;

    }

  };


  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Inbound Plan' : 'الباقة الداخلية'} </h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">Question</h4>

              <p>{ques}</p>

              <Row style={{ direction: 'ltr' }}>
                <Col md={4}>
                  <div class="align-items-center travel-selection-option">
                    {(lnstr === 'en') ? 'YES' : 'نعم'}
                    <input type="radio" name="questionans" value="Yes" onChange={handleqOptionChange} className="d-inline-block option-circle ml-auto" />
                  </div>
                </Col>

                <Col md={4}>
                  <div class="align-items-center travel-selection-option">
                    {(lnstr === 'en') ? 'NO' : 'لا'}
                    <input type="radio" name="questionans" value="No" onChange={handleqOptionChange} className="d-inline-block option-circle ml-auto" />
                  </div>
                </Col>
              </Row>

              <Row>

                <Col md={12}>
                  <br />

                  <b>Please put your signature here</b><br />

                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                    style={{ border: '1px solid #ccc' }}
                    onEnd={handleSignature} // Detect when drawing ends
                  />
                  <div>
                    <button type="button"
                      style={{ backgroundColor: !isSignatureEmpty ? '#61dafb' : 'grey', borderColor: '#61dafb' }}
                      className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                        clearSignature();
                      }}
                      disabled={isSignatureEmpty}
                    >
                      Clear Signature
                    </button>
                  </div>

                </Col>

              </Row>


              <Row>

                <Col md={12} style={{ direction: 'ltr' }}>

                  <p><input type="checkbox" checked={isChecked}
                    onChange={handleCheckboxChange} name="note" /> I/We hereby declare that to the best of my/our knowledge and belief, the above statements and particulars are complete and true and that I/We have not misstated or suppressed any material facts. (A material fact is one which is likely to influence Superjetom company acceptance or assessment of this proposal. If in any doubt whether facts are material, they should be disclosed). Submitting this form does not bind the Proposer to complete the Insurance, nor Superjetom company to accept, but it is agreed that this form shall be the basis of the contract should a policy be issued.</p>

                </Col>

              </Row>


              <button type="submit" disabled={!isChecked} onClick={handleSubmit} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default Insurancequestion;