import React, { useState, useEffect } from 'react';
import logoimg from './logo.png';
import logogreenimg from './logogreen.png';
import whatsappimg from './whatsap.png';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import Cookies from 'js-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Navbar = () => {


  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  

  const [userData, setUserData] = useState();
  
  useEffect(() => {
  const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
  }, []);

  
  const { t } = useTranslation();

  const lngstr = Cookies.get('langData') ? (Cookies.get('langData')==='ar') ? 'عربي' : 'English' : 'English';

  const [sellng, setsellng] = useState(lngstr);

  const changeLanguage = (lng) => {

    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

    Cookies.set('langData', lng, { expires: expiryDate });

    // sessionStorage.setItem("langData", lng);

    if(lng==='ar')
    {
      setsellng('العربية');
    }
    else
    {
      setsellng('English');
    }

    i18n.changeLanguage(lng);
  };


  // Function to update HTML dir attribute based on language
  const updateHtmlDirAttribute = () => {
    document.documentElement.dir = i18n.dir(); // Set document direction
  };

  // Update HTML dir attribute when component mounts and language changes
  useEffect(() => {
    updateHtmlDirAttribute();
  }, [i18n.language]);



    const currentPageUrl = window.location.href;

    const currentPageName = currentPageUrl.substring(currentPageUrl.lastIndexOf('/') + 1);



  return (
    <div>

    {(currentPageName === '') ? 
    <HelmetProvider>
    <Helmet>
      <title>SUPJERJET | Visa at click. Insurance for everything.</title>
      <meta name="description" content="Visa at click. Insurance for everything." />
      <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
      <link rel="canonical" href={currentPageUrl} />
    </Helmet>
    </HelmetProvider> : (currentPageName === 'visa') ?  <HelmetProvider>
     <Helmet>
       <title>e-Visa | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'insurance') ?  <HelmetProvider>
     <Helmet>
       <title>e-Insurance | SUPERJET</title>
       <meta name="description" content="Insurance for everything." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'application') ?  <HelmetProvider>
     <Helmet>
       <title>e-Visa | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'verify') ?  <HelmetProvider>
     <Helmet>
       <title>e-Visa | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'myorders') ?  <HelmetProvider>
     <Helmet>
       <title>e-Visa - My Orders | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'myprofile') ?  <HelmetProvider>
     <Helmet>
       <title>My Profile | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'order-page') ?  <HelmetProvider>
     <Helmet>
       <title>e-Visa | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'travel-insurance') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'inbound-travel-details') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'inbound-travel-info') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'inbound-traveller-info') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'insurance-plan') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'insurance-question') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'insurance-summary') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'outbound-travel-details') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'outbound-traveller-info') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'outbound-travel-info') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'outbound-summary') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'outbound-plan') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'outbound-question') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Insurance | SUPERJET</title>
       <meta name="description" content="Travel Insurance is an essential cover for people travelling abroad for leisure holidays, business trips and short stays." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'about-us') ?  <HelmetProvider>
     <Helmet>
       <title>About Us | SUPERJET</title>
       <meta name="description" content="SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. " />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'api-page') ?  <HelmetProvider>
     <Helmet>
       <title>API | SUPERJET</title>
       <meta name="description" content="SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. " />
       <meta name="keywords" content="e-Visa, Superjetom, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'travel-agent') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Agent | SUPERJET</title>
       <meta name="description" content="SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. " />
       <meta name="keywords" content="e-Visa, Superjetom, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'terms') ?  <HelmetProvider>
     <Helmet>
       <title>Terms of Use | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Superjetom, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'apipage') ?  <HelmetProvider>
     <Helmet>
       <title>API | SUPERJET</title>
       <meta name="description" content="Our APIs are designed to simplify the process of obtaining eVisas and insurance, providing your customers with a smooth and efficient experience." />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Superjetom, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'travelagent') ?  <HelmetProvider>
     <Helmet>
       <title>Travel Agents | SUPERJET</title>
       <meta name="description" content="SuperJet simplifies travel by facilitating border crossing administration and insurance needs. " />
       <meta name="keywords" content="e-Visa, Travel Insurance, Superjetom, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'login') ?  <HelmetProvider>
     <Helmet>
       <title>Login | SUPERJET</title>
       <meta name="description" content="SuperJet simplifies travel by facilitating border crossing administration and insurance needs. " />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Superjetom, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : (currentPageName === 'signup') ?  <HelmetProvider>
     <Helmet>
       <title>Registration | SUPERJET</title>
       <meta name="description" content="SuperJet simplifies travel by facilitating border crossing administration and insurance needs. " />
       <meta name="keywords" content="e-Visa, Travel Insurance, Superjetom, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider>:  (currentPageName === 'travellerlist') ?  <HelmetProvider>
     <Helmet>
     <title>e-Visa - My Travellers | SUPERJET</title>
       <meta name="description" content="Visa at a click. Travel made simple" />
       <meta name="keywords" content="e-Visa, Travel Insurance, Insurance, Visa Services, Visa, e-Insurance" />
       <link rel="canonical" href={currentPageUrl} />
     </Helmet>
     </HelmetProvider> : '' }
      
      <div className="header_main">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="logo"><a href="/">{currentPageName=='application-verify'?<img src={logogreenimg} alt="SUPERJET" />:<img src={logoimg} alt="SUPERJET" />}</a></div>
              {/* <div className="logo"><a href="/"><img src={logoimg} alt="SUPERJET" /></a></div> */}
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto mx-2 my-2">

                <li className="nav-item dropdown">
                  {(currentPageName === 'visa' || currentPageName === 'insurance') ? <div><a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Explore')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a href="/visa" className="dropdown-item">{(sellng==='English') ? <span>e-Visa</span> : <span>التأشيرات الإلكترونية </span>}</a>
                    <a href="/insurance" className="dropdown-item">{(sellng==='English') ? <span>e-Insurance</span> : <span>التأمين الإلكتروني </span>}</a>
                    </div></div> : <div><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Explore')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a href="/visa" className="dropdown-item">{(sellng==='English') ? <span>e-Visa</span> : <span>التأشيرات الإلكترونية </span>}</a>
                    <a href="/insurance" className="dropdown-item">{(sellng==='English') ? <span>e-Insurance</span> :
                        <span>التأمين الإلكتروني </span>}</a>
                    </div></div> }
                  </li>

                  <li className="nav-item dropdown">
                  {(currentPageName === 'api-page' || currentPageName === 'travel-agent')
                      ?
                      <div>
                        <a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Partner With Us')}</a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a href="/api-page" className="dropdown-item">API</a>
                            <a href="/travel-agent" className="dropdown-item">{(sellng==='English') ? <span>Travel Agents</span> : <span>طلب شراكة
                              </span>}</a>
                        </div>
                      </div>
                      :
                      <div>
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('Partner With Us')}</a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a href="/api-page" className="dropdown-item">API</a>
                              <a href="/travel-agent" className="dropdown-item">{(sellng==='English') ? <span>Travel Agents</span> : <span>طلب شراكة
                                </span>}</a>
                        </div>
                      </div> }
                  </li>


                  <li className="nav-item dropdown">
                  {(currentPageName === 'about-us') ? <div><a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('About Us')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a href="/about-us" className="dropdown-item">{(sellng==='English') ? <span>The Company</span> : <span>نبذة عنا
</span>}</a>
                    </div></div> : <div><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('About Us')}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a href="/about-us" className="dropdown-item">{(sellng==='English') ? <span>The Company</span> : <span>نبذة عنا
                              </span>}</a>
                    </div></div> }
                  </li>


                  <li className="nav-item dropdown">
                  {userData ? (<div><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{userData.agency_name != null && userData.agency_name != undefined ? userData.agency_name: userData.first_name}</a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a href="/profile" className="dropdown-item">{t('My')} {t('Profile')}</a>
                            
                            <a href="/myorders" className="dropdown-item">{t('My')} {t('Orders')}</a>
                          {userData != null && userData != undefined && userData.agency_name != null  && userData.agency_name != undefined &&  <a href="/travellerlist" className="dropdown-item">{t('My')} {t('Travellers')}</a>}
                            <a href="/logout" className="dropdown-item">{t('Logout')}</a>
                    </div></div>) : ( currentPageName === 'login' || currentPageName === 'signup' ? <a className="nav-link active" href="/login">{(sellng==='English') ? <span>Login / Signup</span> : <span>اشتراك / تسجيل دخول
                      </span> }</a> : <a className="nav-link" href="/login">{(sellng==='English') ? <span>Login / Signup</span> : <span>اشتراك / تسجيل دخول
</span> }</a> )}
                  </li>
      
                  
                </ul>

                <div class="dropdown mr-5">
                {currentPageName=='application-verify'? <button class="btn btn-secondary dropdown-toggle" style={{ backgroundColor: '#00EDA2',borderColor:'#00EDA2' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sellng}</button>:<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sellng}</button>}
                    {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sellng}</button> */}
                    <div class="dropdown-menu dropdown-menu1" aria-labelledby="dropdownMenuButton">
                      {(sellng!=='English') ? <button className="dropdown-item" onClick={() => changeLanguage('en')}>English</button> : '' }
                      {(sellng==='English') ? <button className="dropdown-item" onClick={() => changeLanguage('ar')}>العربية</button> : '' }
                    </div>
                  </div>

                {/* <p class="call_text"><a href="https://wa.me/96877074345?text=support"><img src={whatsappimg} alt="SUPERJET" /> {(sellng==='English') ? <span>(968)77074345</span> : <span>77074345(968)</span>}</a></p> */}
                {/* <img src={whatsappimg} alt="SUPERJET" /> */}
              </div>
            </nav>
          </div>
      </div>
        
    </div>
  );
}

export default Navbar;