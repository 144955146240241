import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './planeblack.png';
import Cookies from 'js-cookie';

function Partner() {

    const { t } = useTranslation();

    // const [insuretoken, setInsuretoken] = useState(null);
    // useEffect(() => {
    //     fetch('https://api.superjetom.com/create_inbound_token')
    //       .then(response => response.json())
    //       .then(data => {
    //         // debugger;
    //         setInsuretoken(data.result.data.token);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching countries:', error);
    //       });
    //   }, []);
      

    const handleClick = () => {
        
        // if(Cookies.get('userData'))
        // {
            
        
            window.location.href = 'travel-insurance';
            // window.location.href = 'personal-accident-insurance';
        
        // }
        // else
        // {
        //     sessionStorage.setItem("searchData", "");
        //     alert("Login required.");
        //     window.location.href = '/Login';
        // }

      };

      const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  return (
    <div className="App">
      

      <div className="header_section1">

      <Navbar />


      <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('INSURANCE')}</h3>
            </div>
        </div>

      
      </div>

      


      <div class="features_section layout_padding">
        <div class="container">
          <h1 class="features_text">
            
          {(lnstr==='en') ? <div> {t('Our')} <span className="htext"> {t('Products')}</span> </div> : 'منتجاتنا ' }
            
            </h1>
          <p className="text-center undertxt">{t('Offered by us to cover all your needs')}.</p>
          <div class="features_section2">
            <div class="row">
            
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp">
              
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 text-center wow fadeInUp" style={{cursor: 'pointer'}} data-wow-delay="0.1s" onClick={handleClick}>
                        <div className="service-item rounded">
                           <div className="service-img rounded-top">
                                
                           </div>
                            <div className="service-content rounded-bottom card bg-light p-4">
                                <div className="service-content-inner">
                                    {/* <img src={travelimg} alt="SUPERJET" /> */}
                                    <p className="mt-5 pt-5 text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                                    <h5 className="mt-3 text-center">{t('Travel Insurance')}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-3 col-xl-3 text-center wow fadeInUp" style={{cursor: 'pointer'}} data-wow-delay="0.1s" onClick={handleClick}>
                        <div className="service-item rounded">
                           <div className="service-img rounded-top">
                                
                           </div>
                            <div className="service-content rounded-bottom card bg-light p-4">
                                <div className="service-content-inner">
                                    
                                    <p className="mt-5 pt-5 text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                                    <h5 className="mt-3 text-center">{t('Personal Accident Insurance')}</h5>
                                </div>
                            </div>
                        </div>
                    </div> */}
              
              
              
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default Partner;