import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';

function Insurancesummary() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();

    const [dhofarData, setDhofarData] = useState(false);  
    useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarData');
      if (storedDhofarString!=='undefined') {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // debugger
        // alert("token expired");
        // window.location.href='/';
      }
    }, []);

    const [appData, setAppData] = useState(false);
    useEffect(() => {
      const storedAppString = sessionStorage.getItem('induranceapplicationData');
        if (storedAppString!=='undefined') {
          const storedApp = JSON.parse(storedAppString);
          setAppData(storedApp);
        }
        else
        {
          debugger
          // alert("token expired");
          // window.location.href='/';
        }
    }, []);


    const [policyData, setPolicyData] = useState(false);
    useEffect(() => {
      const storedPolicyString = sessionStorage.getItem('policyNumber');
        if (storedPolicyString!=="undefined") {
          const storedPolicy = JSON.parse(storedPolicyString);
          setPolicyData(storedPolicy);
        }
        else
        {
          debugger
          // alert("token expired");
          // window.location.href='/';
        }
    }, []);


    const [plData, setPlData] = useState(false);
    useEffect(() => {
      const storedPlString = sessionStorage.getItem('insurancePlan');
        if (storedPlString!==undefined) {
          const storedPl = JSON.parse(storedPlString);
          setPlData(storedPl);
        }
    }, []);



    const [vendorKey, setVendorKey] = useState('');
  
    useEffect(() => {

      const postvData = { organization_name: "Superjet" };

      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
        
        
        fetch('https://api.superjetom.com/create_organization', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
          })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
              console.error('Error posting data:', error);
          });


      }
    }, [vendorKey]); // Dependency array



    debugger
    // const redirect_url = "https://superjetom.com/";
    const redirect_url = "https://superjetom.com/download-policy?policyno="+policyData;

    const [paymentUrl, setPaymenturl] = useState('');

    const postData = [{ token: dhofarData }, {policy_number: policyData}, {redirect_url}];

    if(vendorKey!=='' && paymentUrl==='')
    {

      fetch('https://api.superjetom.com/get_inbound_paymentlink', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      })
      .then(response => response.json())
      .then(data => {
          setPaymenturl(data.result.data.payment_url);
      })
      .catch(error => {
          console.error('Error posting data:', error);
      });

    }


  return (
    <Fragment>

      <div className="header_section1">

      <Navbar />


      <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Inbound Plan' : 'الباقة الداخلية'}</h3>
            </div>
        </div>


      </div>
       
 
        <div className="features_section">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

                    <Col md={6} className="myforms my-5">

                            <h4 className="px-0 py-2">Summary</h4>

                            

                            <div className="colmds-4 card m-0">

                            <Row className="py-2" style={{direction: 'ltr' }}>

                            <Col md={6}>
                            
                            Travel Type<br />

                            <b>{appData.inbound_product_name}</b><br /><br />


                            Coverage Type<br />

                            <b>{appData.inbound_coverage_name}</b><br /><br />


                            


                            Traveller Type<br />

                            <b>Individual</b>
                            
                            </Col>

                            <Col md={6}>

                            Travel Start Date<br />

                            <b>{(appData.inbound_travel_start_date) ? format(new Date(appData.inbound_travel_start_date), 'dd-MM-yyyy') : '' }</b><br /><br />
                            

                            Travel Duration<br />

                            <b>{appData.cumulative_days}</b><br /><br />
                            
                            Plan<br />

                            <b>{appData.inbound_product_name}</b><br /><br />
                            
                            </Col>

                            <br /><br />

                            </Row>

                            </div>

                            

                            <br />

                            <b>Traveller Details</b>

                            <div className="mobdiv" style={{direction: 'ltr' }}>

                            <table className="table table-bordered table-condenced">

                            <tbody>

                            <tr><td>First Name</td><td><b>{appData.first_name}</b></td>
                            <td>Last Name</td><td><b>{appData.last_name}</b></td></tr>

                            <tr><td>Email</td><td><b>{appData.email}</b></td>
                            <td>Mobile</td><td><b>{appData.mobileNumber}</b></td></tr>

                            <tr><td>Passport</td><td><b>{appData.passport}</b></td>
                            <td>Relation</td><td><b>Principal</b></td></tr>

                            <tr><td>Gender</td><td><b>{appData.inbound_gender_detail}</b></td>
                            <td>Marital Status</td><td><b>{appData.inbound_marital_status_detail}</b></td></tr>

                            <tr><td>DOB</td><td><b>{(appData.dob) ? format(new Date(appData.dob), 'dd-MM-yyyy') : '' }</b></td>
                            
                            <td>Nationality</td><td><b>{appData.nationality}</b></td></tr>

                            </tbody>

                            </table>

                            </div>

                            <br />

                            <b>Premium Details</b>

                            <table className="table table-bordered table-condenced" style={{direction: 'ltr' }}>

                            <tbody>

                            <tr><td>
                            Basic premium</td><td><b>{plData.basicplan} OMR</b></td></tr>

                            <tr><td>
                            Policy Fee</td><td><b>{plData.policy_fee} OMR</b></td></tr>

                            <tr><td>
                            Government Fee</td><td><b>{plData.government_fee} OMR</b></td></tr>

                            <tr><td>
                            Emergency Fund Fee</td><td><b>{plData.emergency_fund_fee} OMR</b></td></tr>

                            <tr><td>
                            VAT</td><td><b>{plData.vat} OMR</b></td></tr>

                            <tr><td>
                            Total</td><td><b>{plData.total} OMR</b></td></tr>


                            </tbody>

                            </table>

                            <a href='inbound-travel-details'><button type="button" class="btn btn-primary-outline">Edit</button></a> &nbsp; <a href={(paymentUrl) ? paymentUrl : ''}><button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-2 px-5">{(lnstr === 'en') ? 'Proceed to payment' : 'الدفع'}</button></a>


                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    {/* {loading && (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    )} */}

    </Fragment>
  );
}

export default Insurancesummary;