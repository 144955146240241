import React, { Fragment, useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from './loaderred.gif';
import { format } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const TravellerList = ({ usrtok }) => {

  const { t } = useTranslation();

  const [userData, setUserData] = useState(false);

  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);

  const [odata, setOdata] = useState([]);
  const [orddata, setOrddata] = useState([]);

  if (!Cookies.get('userData')) {
    alert('Login Session Expired');
    window.location.href = '/';
  }

  const handleClose = () => {
    setShow(false);
  };




  useEffect(() => {

    // debugger;

    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString !== 'undefined') {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
  }, []);

  usrtok = userData.access_token;

  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);


  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array



  const handleStatus = async (e) => {
    // // debugger;
    // const frmData = new FormData();
    // frmData.append('agency_id', e.target.value);
    // // frmData.append('vendor_key', vendorKey);
    //   // console.log(frmData)
    // try {
    //   // debugger
    //   const response = await axios.post('https://api.superjetom.com/traveller_by_agent_id', frmData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   });
    //   debugger
    //   // console.log('Image upload successful:', response.data);
    //   setOdata(response.data.result.order.applications[0]);
    //   setOrddata(response.data.result.order.id);
    //   setShow(true);
    // } catch (error) {
    //   console.error('Error uploading image:', error);
    //   // Handle error, show error message, etc.
    // }
  };




  useEffect(() => {

    debugger

    // var vkData = { vendor_key: vendorKey };
    var vkData = { vendor_key: vendorKey };

    if (!Cookies.get('userData')) {
      alert('Login Session Expired');
      window.location.href = '/';
    }
    else if (vendorKey !== '' && profile == null) {


      fetch('https://api.superjetom.com/traveller_by_agent_id', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${usrtok}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vkData),
      })
        .then(response => response.json())
        .then(data => {
          debugger
          setProfile(data);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
    }
  }, [usrtok, vendorKey]);



  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     try {
  //       // Fetch profile data using token
  //       const response = await fetch('https://api.superjetom.com/get_orders_by_traveller_id', {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${usrtok}` // <-- Using 'usrtok' here
  //           //'Content-Type': 'application/json' // Adjust content type as needed
  //         }
  //       });


  //       if (response.ok) {
  //       const profileData = await response.json();
  //       setProfile(profileData);
  //       }

  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchProfile();

  //   // Clean up function to prevent memory leaks
  //   return () => {
  //     setProfile(null);
  //     setError(null);
  //   };
  // }, [usrtok]); // <-- Add 'usrtok' to the dependency array

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  if (!profile) {
    return <div className="overlay">
      <div className="spinner"></div>
    </div>;
  }



  return (
    <div>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('MY')} {t('TRAVELLERS')}</h3>
          </div>
        </div>


      </div>


      <div className="features_section layout_padding my-1">
        <div className="container">

          <div className="row g-4 justify-content-center">

            <div className="col-12 wow fadeInUp" data-wow-delay="0.2s" style={{ overflowX: 'auto' }}>


              <h3>{t('My')} {t('Travellers')}</h3>

              <table className='table table-bordered mt-3'>

                {/* <thead><tr><th>#</th><th>{t('Traveller')} {t('ID')}</th><th>{t('First Name')}</th><th>{t('Last Name')}</th>
                  <th>{t('Email')}</th><th>{t('Created')}</th><th>{t('Status')}</th></tr></thead> */}
                   <thead><tr><th>#</th><th>{t('Traveller')} {t('ID')}</th><th>{t('First Name')}</th><th>{t('Last Name')}</th>
                  <th>{t('Email')}</th><th>{t('Created')}</th></tr></thead>
                <tbody>

                  {(profile.result.length === 0) ? <tr><td colSpan={6} className='text-center'>{t('No')} {t('Records')} {t('Found')}</td></tr> : ''}

                  {profile.result.map((prof, index) => (

                    <tr><td>{index + 1}</td><td>{prof.traveller_id}</td><td>{t(prof.first_name)}</td>
                      <td>{t(prof.last_name)}</td>
                      <td>{prof.email}</td>
                      <td>{format(new Date(prof.created_at), 'dd/MM/yyyy')}</td>
                      {/* <td><Button variant="primary" style={{ fontWeight: 'normal' }} className="btn btn-primary rounded-pill text-white py-1 px-2" onClick={handleStatus} value={prof.order_id}>
                        {t('Check')} {t('Status')}
                      </Button></td> */}
                      </tr>

                  ))}

                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>


      <Footer />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{ paddingLeft: 10, paddingRight: 10 }}>{t('Order')} {t('Status')}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className='my-5'>
          {/* {(odata.applications) ? <p>{odata.applications}</p> : '' }  */}
          <table>
            <tr><td>{t('Order')} {t('ID')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td><b>{orddata}</b></td></tr>
            <tr><td>{t('Status')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata.processing_status}</b></td></tr>
          </table>
          <button type="button" class="btn btn-close" aria-label="Close" onClick={handleClose}>Close</button>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default TravellerList;