import React, { Fragment, useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import sideimg from './side7.jpg';
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { submitForm } from './search/redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Select2Wrapper from './Select2Wrapper';
import Cookies from 'js-cookie';

function Visa({ onSubmitForm, message, searchresult, flagresult, embassyresult, error  }) {

  const [formData, setFormData] = useState({
    destination: '',
    arraival: '',
    lengthofstay: ''
  });

  const [options, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedArrival, setSelectedArraival] = useState('');
  const [loading, setLoading] = useState(false);

  const [vendorKey, setVendorKey] = useState('');
  
  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
      
      
      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
        })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
            console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array

  
  
  useEffect(() => {

    if(vendorKey!=='' && options.length===0)
    {

      var vkData = {vendor_key: vendorKey};

      fetch('https://api.superjetom.com/country', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vkData),
      })
          .then(response => response.json())
          .then(data => {
            // debugger
            setCountries(data.result);
          })
          .catch(error => {
            console.error('Error fetching countries:', error);
          });

    }

  }, [vendorKey]);

  const handledestinationChange = (value) => {

    setSelectedCountry(value);

    setFormData({ ...formData, 'destination': value });
  };

  const handlearrivalChange = (e) => {

    setSelectedArraival(e);

    setFormData({ ...formData, 'arraival': e });
  };


const handleChange = (event) => {
const { name, value } = event.target;

// Ensure only numbers are entered for mobile number
if (name === 'lengthofstay') {
  const regex = /^[0-9\b]+$/; // Regular expression to match only digits
  if (value === '' || regex.test(value)) {
    setFormData({ ...formData, [name]: value });
  }
} else {
  setFormData({ ...formData, [name]: value });
}
};

const handleSubmit = (event) => {
// debugger;

event.preventDefault();

if(selectedCountry==='') {
    alert('Please select an destination country.');
    return false;
  }
else if(selectedArrival==='') {
    alert('Please select an passport country.');
    return false;
  }
else
{
setLoading(true);

event.preventDefault();

  var searchdata   = [{
    'destination': formData.destination,
    'citizenship': formData.arraival,
    'purpose': 'tourism'
    
  },
  {
  'vendor_key': vendorKey
  }
  ];

  // var vData = { vendor_key: vendorKey };

  sessionStorage.setItem("searchData", JSON.stringify(searchdata[0]));

  // debugger;

  onSubmitForm(searchdata);

  // window.location.href = `/Visatypes`;
}

};

if(message==='success')
{
const udata = searchresult;
const fldata = flagresult;  
const emdata = embassyresult;

if(udata!=='')
{
sessionStorage.setItem("searchResult", JSON.stringify(udata));
sessionStorage.setItem("flagResult", JSON.stringify(fldata));
sessionStorage.setItem("embassyResult", JSON.stringify(emdata));
}

window.location.href = `/visatypes`;

}

const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


const { t } = useTranslation();

  return (
    <div className="App">
      

      <div className="header_section">

      <Navbar />

      <div className="banner_section layout_padding">
        <div className="container">
          
          <h1 className="discover_text">{(lnstr==='en') ? <span>Simplifying world travel  <br /> modernizing your visa </span>: <span>تبسيط السفر حول العالم<br />قدّم طلبك للحصول على التأشيرة الإلكترونية الآن
</span> }</h1>

          <div className="banner_section2">
          <form onSubmit={handleSubmit}>
            <div className="row">
            <div className="col-md-2 mb-1"></div>
              <div className="col-md-4 mb-1">
                <label className="ml-3">{t('Destination Country')} *</label>
                  <Select2Wrapper name="destination" options={options} value={selectedCountry} onChange={handledestinationChange} className='form-control form-control-spl' required />
              </div>
              <div className="col-md-4 mb-1">
                <label className="ml-3">{t('Passport Country')} *</label>
                  <Select2Wrapper name="arraival" options={options} value={selectedArrival} onChange={handlearrivalChange} className='form-control form-control-spl' required />
              </div>
            <div className="col-md-2 mb-1"></div>
              {/* <div className="col-md-4 mb-1">
              <label className="ml-3">No.of Days *</label>
                  <input type="text" className="form-control mform-control" name="lengthofstay" value={formData.lengthofstay}
          onChange={handleChange} maxLength={3} required autoComplete='off' />
              </div> */}
            </div>
            <div className="start_bt">
              {/* <div className="Search_bt"><a href="#">Search</a></div> */}
              <Button type="submit" className="Search_bt btn-danger" style={{ verticalAlign: 'middle', paddingTop: 13, paddingBottom: 13 }}>
                        {(lnstr==='en') ? <span>Find e-Visa</span> : <span>بحث تأشيرة الألكترونية </span> } <br />
                            </Button>
            </div>
            </form>
            
          </div>
          
        </div>
      </div>
      </div>


      <Footer />

      {loading && (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    )}

    </div>
  );
}

const mapStateToProps = state => ({
  message: state.visasearch.message,
  searchresult: state.visasearch.searchretdata,
  flagresult: state.visasearch.countryflagdata,
  embassyresult: state.visasearch.embassydata,
  error: state.visasearch.error
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: (formData) => dispatch(submitForm(formData)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(Visa);