import React, { Fragment, useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from './loaderred.gif';
import { format } from 'date-fns';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Myorders = ({ usrtok }) => {

  const { t } = useTranslation();

  const [userData, setUserData] = useState(false);

  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);

  const [odata, setOdata] = useState([]);
  const [orddata, setOrddata] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Visa Orders');

  if (!Cookies.get('userData')) {
    alert('Login Session Expired');
    window.location.href = '/';
  }

  const handleClose = () => {
    setShow(false);
  };




  useEffect(() => {

    debugger;

    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString !== 'undefined') {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
  }, []);

  usrtok = userData.access_token;

  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);


  const [vendorKey, setVendorKey] = useState('');
  const [totalEarnings, setTotalEarnings] = useState('');
  const [insuranceData, setInsuranceData] = useState(null);

  const handleSelect = (eventKey) => {
    debugger
    setSelectedOption(eventKey);
    setTotalEarnings(0);

    if (eventKey == "Visa Orders") {
      var vkData = { vendor_key: vendorKey };
        var get_order_url = "";
        if (userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
          get_order_url = "https://api.superjetom.com/orders_by_agency";
        } else {
          get_order_url = "https://api.superjetom.com/get_orders_by_traveller";
        }
        debugger
        fetch(get_order_url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${usrtok}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(vkData),
        })
          .then(response => response.json())
          .then(data => {
            debugger
            if (data != null && data.total_earning != undefined && userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
              setTotalEarnings(data.total_earning);
            }

            setProfile(data);
          })
          .catch(error => {
            console.error('Error fetching countries:', error);
          });

    } else {
      debugger
      var vkData = { agent_token: userData.access_token };
      var get_insurance_url = "";
      if (userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
        get_insurance_url = "https://api.superjetom.com/get_insurance_list_by_agent";
        // get_insurance_url = "http://127.0.0.1:3001/get_insurance_list_by_agent";
      } else {
        // get_insurance_url = "https://api.superjetom.com/get_orders_by_traveller";
      }
      debugger
      fetch(get_insurance_url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vkData),
      })
        .then(response => response.json())
        .then(data => {
          debugger
          if (data.message == 'success') {
            setInsuranceData(data.result);
            debugger
            if (data != null && data.total_earning != undefined && userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
              setTotalEarnings(data.total_earning);
            }
          }

        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
    }
  };

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
      fetch('https://api.superjetom.com/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array



  const handleStatus = async (e) => {
    debugger;
    const frmData = new FormData();
    frmData.append('order_id', e.target.value);
    frmData.append('vendor_key', vendorKey);
    // console.log(frmData)
    try {
      // debugger
      const response = await axios.post('https://api.superjetom.com/each_order_details', frmData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // console.log('Image upload successful:', response.data);
      var order_details = response.data.result.order.applications;
      if (order_details[0] == '[') {
        var orders = JSON.parse(order_details);
        order_details = orders[0];
      } else {
        order_details = order_details[0];
      }
      // order_details = order_details[0] == '['?JSON.parse(order_details):order_details[0];
      // setOdata(response.data.result.order.applications[0]);
      // setOdata(order_details);
      setOdata(response.data.result.order);
      setOrddata(response.data.result.order.order_id);
      setShow(true);
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error, show error message, etc.
    }
  };


  useEffect(() => {

    // debugger



    if (!Cookies.get('userData')) {
      alert('Login Session Expired');
      window.location.href = '/';
    }
    else if (vendorKey !== '' && profile == null) {

      if (selectedOption == 'Visa Orders') {
        var vkData = { vendor_key: vendorKey };
        var get_order_url = "";
        if (userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
          get_order_url = "https://api.superjetom.com/orders_by_agency";
        } else {
          get_order_url = "https://api.superjetom.com/get_orders_by_traveller";
        }
        debugger
        fetch(get_order_url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${usrtok}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(vkData),
        })
          .then(response => response.json())
          .then(data => {
            debugger
            if (data != null && data.total_earning != undefined && userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
              setTotalEarnings(data.total_earning);
            }

            setProfile(data);
          })
          .catch(error => {
            console.error('Error fetching countries:', error);
          });
      } else {
        // var vkData = { agent_token: userData.access_token };
        // var get_insurance_url = "";
        // if (userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
        //   get_insurance_url = "https://api.superjetom.com/get_insurance_list_by_agent";
        // } else {
        //   // get_insurance_url = "https://api.superjetom.com/get_orders_by_traveller";
        // }
        // debugger
        // fetch(get_insurance_url, {
        //   method: 'POST',
        //   headers: {
        //     'Authorization': `Bearer ${vendorKey}`,
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify(vkData),
        // })
        //   .then(response => response.json())
        //   .then(data => {
        //     debugger
        //     if (data != null && data.total_earning != undefined && userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
        //       setTotalEarnings(data.total_earning);
        //     }

        //     setProfile(data);
        //   })
        //   .catch(error => {
        //     console.error('Error fetching countries:', error);
        //   });
      }
    }
  }, [usrtok, vendorKey]);



  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     try {
  //       // Fetch profile data using token
  //       const response = await fetch('https://api.superjetom.com/get_orders_by_traveller_id', {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${usrtok}` // <-- Using 'usrtok' here
  //           //'Content-Type': 'application/json' // Adjust content type as needed
  //         }
  //       });


  //       if (response.ok) {
  //       const profileData = await response.json();
  //       setProfile(profileData);
  //       }

  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchProfile();

  //   // Clean up function to prevent memory leaks
  //   return () => {
  //     setProfile(null);
  //     setError(null);
  //   };
  // }, [usrtok]); // <-- Add 'usrtok' to the dependency array

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  if (!profile) {
    return <div className="overlay">
      <div className="spinner"></div>
    </div>;
  }



  return (
    <div>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('MY')} {t('ORDERS')}</h3>
          </div>
        </div>


      </div>


      <div className="features_section layout_padding my-1">
        <div className="container">

          <div className="row g-4 justify-content-center">

            <div className="col-12 wow fadeInUp" data-wow-delay="0.2s" style={{ overflowX: 'auto' }}>


              {/* <h3>{t('My')} {t('Orders')}</h3> */}
              {/* <h3>{t('TotalEarnings : ')}{t(totalEarnings)} </h3> */}
              {/* <div className='row'>
                <div className='col-6'></div>
                <div className='col-6'>
                  <div className='row'>
                    <h3>{t('Total Earnings : ')}{t(totalEarnings)} </h3>
                    <Dropdown onSelect={handleSelect} style={{ float: 'right', paddingBottom: '20px' }}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {t(selectedOption)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Visa Orders">{t('Visa Orders')}</Dropdown.Item>
                        <Dropdown.Item eventKey="Insurance Orders">{t('Insurance Orders')}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                </div>

              </div> */}

              { userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined ?

              <div className='row'>
                <div className='col-6'><b style={{ textTransform: 'uppercase', fontSize: 17 }}>{userData.agency_name != null && userData.agency_name != undefined ? userData.agency_name: <span>{userData.first_name} {userData.last_name}</span>}</b></div>
                <div className='col-6'>
                  <div className='row justify-content-end'>
                    <div className='col-auto'>
                      <h3>{t('Total Earnings')} : {t(totalEarnings)}</h3>
                    </div>
                    <div className='col-auto'>
                      <Dropdown onSelect={handleSelect} style={{ float: 'right', paddingBottom: '20px' }}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                          {t(selectedOption)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="Visa Orders">{t('Visa Orders')}</Dropdown.Item>
                          <Dropdown.Item eventKey="Insurance Orders">{t('Insurance Orders')}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div> : <div className='row'><div className='col-md-12'><b style={{ textTransform: 'uppercase', fontSize: 17 }}>{userData.agency_name != null && userData.agency_name != undefined ? userData.agency_name: <span>{userData.first_name} {userData.last_name}</span>}</b></div></div>

               }

              {
                userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined ?
                  selectedOption == 'Visa Orders' ? <table className='table table-bordered mt-3'>
                    <thead><tr><th rowSpan={2}>#</th>
                     <th rowSpan={2}>{t('Date of Application')}</th>
                     <th rowSpan={2}>{t('Order')} {t('ID')}</th> 
                     {/* {<th>{t('User')} {t('ID')}</th>}  */}
                      <th colSpan={2} style={{ textAlign:'center' }}>{t('Travel')}</th>
                      <th rowSpan={2}>{t('Earnings')}</th>
                      <th rowSpan={2}>{t('Status')}</th></tr>
                      
                      <tr><th style={{ width: '15%' }}>{t('From')}</th>
                      <th style={{ width: '15%' }}>{t('To')}</th></tr>

                      </thead>
                    <tbody>

                      {(profile.result.length === 0) ? <tr><td colSpan={6} className='text-center'>{t('No')} {t('Records')} {t('Found')}</td></tr> : ''}
                      {profile.result.map((prof, index) => {
                        const traveller_details = JSON.parse(prof.traveller); // Assuming this is needed elsewhere, but it's not used here.
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{format(new Date(prof.created_at), 'dd/MM/yyyy')}</td>
                            <td>{prof.order_id}</td>
                            {/* <td>{t(traveller_details?.id)}</td> */}
                            <td>{t(prof.arrival)}</td>
                            <td>{t(prof.destination)}</td>
                            {/* {userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined ? <td></td>:  <td>{prof.fee} ({prof.currency})</td>} */}
                            <td>{prof.earnings} ({prof.currency})</td>
                            <td>
                              <Button
                                variant="primary"
                                style={{ fontWeight: 'normal' }}
                                className="btn btn-primary rounded-pill text-white py-1 px-2"
                                onClick={handleStatus}
                                value={prof.order_id}
                              >
                                {t('Check')} {t('Status')}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> : selectedOption != 'Visa Orders' ? 
                    <table className='table table-bordered mt-3'>
                      <thead><tr><th>#</th><th>{t('Reference')} {t('ID')}</th>  {<th>{t('User')} {t('Name')}</th>} <th>{t('Email')}</th><th>{t('MobileNo')}</th>
                        <th>{t('Earnings')}</th><th>{t('Created')}</th></tr></thead>
                      <tbody>

                        {(insuranceData!= null && insuranceData.length === 0) ? <tr><td colSpan={6} className='text-center'>{t('No')} {t('Records')} {t('Found')}</td></tr> : ''}
                        {insuranceData != null &&insuranceData.map((prof, index) => {
                          // const traveller_details = JSON.parse(prof.traveller); // Assuming this is needed elsewhere, but it's not used here.
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{prof.policy_no}</td>
                              {/* <td>{t(traveller_details?.id)}</td> */}
                              <td>{t(prof.first_name)} {t(prof.last_name!=null && prof.last_name != undefined && prof.last_name !='' ?prof.last_name:'')}</td>
                              <td>{t(prof.email)}</td>
                              <td>{t(prof.mobile_no)}</td>
                              <td>{t(prof.agency_commision != null && prof.agency_commision != undefined && prof.agency_commision !='' ? parseFloat(prof.agency_commision).toFixed(2).toString():prof.agency_commision)}</td>
                              {/* {userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined ? <td></td>:  <td>{prof.fee} ({prof.currency})</td>} */}
                              {/* <td>{prof.earnings} ({prof.currency})</td> */}
                              <td>{format(new Date(prof.created_at), 'dd/MM/yyyy')}</td>
                              {/* <td>
                                <Button
                                  variant="primary"
                                  style={{ fontWeight: 'normal' }}
                                  className="btn btn-primary rounded-pill text-white py-1 px-2"
                                  onClick={handleStatus}
                                  value={prof.order_id}
                                >
                                  {t('Check')} {t('Status')}
                                </Button>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> : ''

                  : <table className='table table-bordered mt-3'>
                    <thead><tr><th rowSpan={2}>#</th>
                    <th rowSpan={2}>{t('Date of Application')}</th>
                    <th rowSpan={2}>{t('Order')} {t('ID')}</th> 
                    <th colSpan={2} style={{ textAlign:'center' }}>{t('Travel')}</th>
                      <th rowSpan={2}>{t('Status')}</th></tr>
                      <tr><th style={{ width: '15%' }}>{t('From')}</th><th style={{ width: '15%' }}>{t('To')}</th></tr></thead>
                    <tbody>

                      {(profile.result.length === 0) ? <tr><td colSpan={6} className='text-center'>{t('No')} {t('Records')} {t('Found')}</td></tr> : ''}
                      {/* {profile.result.map((prof, index) => (
      var traveller_details = JSON.parse(prof.traveller);
    <tr><td>{index + 1}</td><td>{prof.order_id}</td><td>{t(prof.arrival)}</td>
      <td>{t(prof.destination)}</td>
      <td>{prof.fee}</td>
      <td>{format(new Date(prof.created_at), 'dd/MM/yyyy')}</td>
      <td><Button variant="primary" style={{ fontWeight: 'normal' }} className="btn btn-primary rounded-pill text-white py-1 px-2" onClick={handleStatus} value={prof.order_id}>
        {t('Check')} {t('Status')}
      </Button></td></tr>
  ))} */}

                      {profile.result.map((prof, index) => {
                        const traveller_details = JSON.parse(prof.traveller); // Assuming this is needed elsewhere, but it's not used here.
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{format(new Date(prof.created_at), 'dd/MM/yyyy')}</td>
                            <td>{prof.order_id}</td>
                            {/* <td>{t(traveller_details?.id)}</td> */}
                            <td>{t(prof.arrival)}</td>
                            <td>{t(prof.destination)}</td>
                            {/* {userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined ? <td></td>:  <td>{prof.fee} ({prof.currency})</td>} */}
                            {/* <td>{prof.earnings} ({prof.currency})</td> */}
                            
                            <td>
                              <Button
                                variant="primary"
                                style={{ fontWeight: 'normal' }}
                                className="btn btn-primary rounded-pill text-white py-1 px-2"
                                onClick={handleStatus}
                                value={prof.order_id}
                              >
                                {t('Check')} {t('Status')}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
              }
              {/* {userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined && <h3 style={{textAlign:"right"}}>{t('Total')} {t('Earnings')} :  {totalEarnings}</h3>} */}
            </div>
          </div>
        </div>
      </div>


      <Footer />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><span style={{ paddingLeft: 10, paddingRight: 10 }}>{t('Order')} {t('Status')}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className='my-5'>
          {/* {(odata.applications) ? <p>{odata.applications}</p> : '' }  */}
          <table>
            <tr><td style={{ fontWeight: 'bold' }}>{t('Order')} {t('ID')}</td><td style={{ paddingLeft: 10, paddingRight: 10, fontWeight: 'bold' }}>:</td><td><b>{orddata}</b></td></tr>
            <tr><td style={{ fontWeight: 'bold' }}>{t('Status')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.order_status != null && odata?.order_status != undefined ? odata?.order_status : ""}</b></td></tr>
            <tr><td style={{ fontWeight: 'bold' }}>{t('First Name')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.first_name != null && odata?.first_name != undefined ? odata?.first_name : ''}</b></td></tr>
            <tr><td style={{ fontWeight: 'bold' }}>{t('Last Name')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.last_name != null && odata?.last_name != undefined ? odata?.last_name : ''}</b></td></tr>
            {/* <tr><td style={{ fontWeight: 'bold' }}>{t('DOB')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.dob != null && odata?.dob != undefined ? odata?.dob.split('T')[0] : ''}</b></td></tr> */}
            <tr><td style={{ fontWeight: 'bold' }}>{t('Email')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td><b>{odata?.email != null && odata?.email != undefined ? odata.email : ''}</b></td></tr>
            {/* <tr><td style={{ fontWeight: 'bold' }}>{t('Phone')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.phone_p != null && odata?.phone_p != undefined ? odata?.phone_p : ''}</b></td></tr> */}
            {/* <tr><td style={{ fontWeight: 'bold' }}>{t('Sex')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.sex != null && odata?.sex != undefined ? odata?.sex : ''}</b></td></tr> */}
            <tr><td style={{ fontWeight: 'bold' }}>{t('Travel From')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.arrival != null && odata?.arrival != undefined ? odata?.arrival : ''}</b></td></tr>
            <tr><td style={{ fontWeight: 'bold' }}>{t('Travel To')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.destination != null && odata?.destination != undefined ? odata?.destination : ''}</b></td></tr>
            {/* <tr><td style={{fontWeight :'bold' }}>{t('Payment Status')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.superjet_payment_status !=null && odata?.superjet_payment_status != undefined ?odata?.superjet_payment_status:''}</b></td></tr> */}
            <tr><td style={{ fontWeight: 'bold' }}>{t('Date of Application')}</td><td style={{ paddingLeft: 10, paddingRight: 10 }}>:</td><td style={{ textTransform: 'capitalize' }}><b>{odata?.visa_created != null && odata?.visa_created != undefined ? odata?.visa_created.split('T')[0] : ''}</b></td></tr>
          </table>
          <button type="button" class="btn btn-sm btn-close" aria-label="Close" onClick={handleClose}>Close</button>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default Myorders;